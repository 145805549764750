import React, { useEffect, useState } from "react";
import HeaderTwo from "../components/HeaderTwo";
import HeroTwo from "../components/HeroTwo";
import AboutTwo from "../components/AboutTwo";
import PortfolioTwo from "../components/PortfolioTwo";
import WhyChooseUsOne from "../components/WhyChooseUsOne";
import GoogleReview from "../components/GoogleReview";
import FooterAreaTwo from "../components/FooterAreaTwo";
import Preloader from "../helper/Preloader";

const HomePageTwo = () => {
	let [active, setActive] = useState(true);
	useEffect(() => {
		setTimeout(function () {
			setActive(false);
		}, 2000);
	}, []);
	return (
		<>
			{/* Preloader */}
			{active === true && <Preloader />}

			{/* Header Two */}
			<HeaderTwo />

			{/* Hero Two */}
			<HeroTwo />

			{/* About Two */}
			<div id="aboutUs" className="about-area-2 bg-smoke">
				<AboutTwo />
			</div>

			{/* About Two */}
			<div id="reviews">
				<GoogleReview />
			</div>

			{/* Portfolio Two */}
			<div id="portfolioSection">
				<PortfolioTwo />
			</div>

			{/* Why Choose Us One */}
			<div id="ourService">
				<WhyChooseUsOne />
			</div>

			{/* Footer Area Two */}
			<div id="ourContact">
				<FooterAreaTwo />
			</div>
		</>
	);
};

export default HomePageTwo;
