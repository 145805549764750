import React from "react";

const ImageImpressum = () => {
	return (
		<>
			<div className="contact-area space">
				<div className="container">
					<div className="title-area">
						{/* <span className="sub-title">Unser Service</span> */}
						<h2 className="sec-title">
							Quellen aller vewendeten Bilder{" "}
							<img src="assets/img/bg/title-bg-shape.png" alt="Heimrath" />
						</h2>
					</div>
					<div className="row gy-4justify-content-center">
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/hero/bg_2.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@grebenyev?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Vlad Grebenyev
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/close-up-photography-of-car-wheel-7eCBiZgyr4E?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/normal/about_1.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@tekton_tools?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Tekton
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/yellow-textile-on-red-and-black-plastic-crate-e6Wr8LaH-Q8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/normal/about_2.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@nampoh?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Maxim Hopman
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/a-man-is-working-on-a-cars-engine-s4d_ESS0ylA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/1.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@christianbuehner?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Christian Buehner
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/grayscale-photo-of-person-playing-piano-Fd6osyVbtG4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/2.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@cakirchoff?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Chad Kirchoff
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/gray-and-black-engine-xe-e69j6-Ds?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/3.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@timothycdykes?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Timothy Dykes
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/black-metal-appliances-np52xKlGB-I?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/4.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@tekton_tools?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Tekton
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/black-and-silver-car-wheel-O_ufcLVTAYw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/5.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@introspectivedsgn?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Erik Mclean
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/white-honda-car-on-road-during-daytime-2MYD5NO0O9k?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/6.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@wap_des_bois?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Alfred Boivin
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/man-in-green-jacket-sitting-on-black-and-yellow-camping-chair-near-tent-during-daytime-XoM0eYSXWMs?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/7.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@fantasyflip?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Philipp Katzenberger
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/black-vehicle-air-vent-RI7oKsZzypw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/portfolio/8.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@wasdrew?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Andras Vas
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/close-up-photography-of-black-vehicle-during-daytime-WIlBF2mABYM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
						<div className="col-xxl-3 col-lg-4 col-md-6">
							<div className="contact-info">
								<img src="assets/img/normal/ww.jpg" alt="Heimrath" />
								Photo by{" "}
								<a href="https://unsplash.com/@krutota?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Anastasiia Krutota
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/photos/red-car-parked-in-front-of-store-otf4dQgdNqo?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
									Unsplash
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ImageImpressum;
