import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
	return (
		<div
			className="hero-wrapper hero-2"
			id="hero"
			style={{ backgroundImage: "url(assets/img/hero/bg_2.jpg)" }}>
			<div className="hero-shape2-1">
				<span className="hero-shape2-2" />
			</div>
			<div className="container">
				<div className="row">
					<div className="col-xxl-6 col-xl-5 col-lg-8">
						<div className="hero-style2">
							<span className="sub-title text-white">Heimrath Wohnmobil und KFZ-Service GmbH</span>
							<h2 className="hero-title text-white">
								Vertrauensvolle, freie KFZ-Werkstatt mit{" "}
								<span>
									<img src="assets/img/hero/hero_shape_2.png" alt="Heimrath" />
									fachkundiger Beratung
								</span>{" "}
							</h2>
							<p className="hero-text text-white">
								Herzlich Willkommen bei der Firma Heimrath Wohnmobil und KFZ-Service GmbH Sie suchen eine
								vertrauensvolle, freie KFZ-Werkstatt mit fachkundiger Beratung und fairen Preisen? Einen
								kompetenten Partner rund um Ihr Wohnmobil und Ihren PKW? Dann sind Sie bei uns genau richtig!
								Mit 35 Jahren Erfahrung in allen Bereichen rund um Ihr Wohnmobil und Ihren PKW, überzeugen wir
								mit fachlicher Kompetenz und einer persönlichen und herstellerneutralen Beratung.
							</p>
							<div className="btn-group">
								<Link
									to="https://www.google.com/maps/search/?api=1&query=Ammerseestr.+15%2C+82239+Alling-Biburg"
									className="btn"
									target="_blank"
									rel="noopener noreferrer">
									Ihr Weg zu Uns
								</Link>
								<div className="call-media-wrap">
									<div className="icon">
										<img src="assets/img/icon/phone-1.svg" alt="Heimrath" />
									</div>
									<div className="media-body">
										<h6 className="title text-white">Rufen Sie uns an:</h6>
										<h4 className="link">
											<Link className="text-white" to="tel:+49814144496">
												+49 81 41-4 44 96
											</Link>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroTwo;
