import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const HeaderTwo = ({ showMenu = true }) => {
	const [active, setActive] = useState(false);
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		var offCanvasNav = document.getElementById("offcanvas-navigation");
		var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

		for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
			offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='mean-expand-class'>+</span>");
		}

		var menuExpand = offCanvasNav.querySelectorAll(".mean-expand-class");
		var numMenuExpand = menuExpand.length;

		function sideMenuExpand() {
			if (this.parentElement.classList.contains("active") === true) {
				this.parentElement.classList.remove("active");
			} else {
				for (let i = 0; i < numMenuExpand; i++) {
					menuExpand[i].parentElement.classList.remove("active");
				}
				this.parentElement.classList.add("active");
			}
		}

		for (let i = 0; i < numMenuExpand; i++) {
			menuExpand[i].addEventListener("click", sideMenuExpand);
		}
		window.onscroll = () => {
			if (window.pageYOffset < 150) {
				setScroll(false);
			} else if (window.pageYOffset > 150) {
				setScroll(true);
			}
			return () => (window.onscroll = null);
		};
	}, []);

	const mobileMenu = () => {
		setActive(!active);
	};
	return (
		<header className="nav-header header-layout2">
			<div className="header-top">
				<div className="container">
					<div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
						<div className="col-auto d-none d-lg-block">
							<div className="header-logo">
								<Link to="/">
									<img src="assets/img/logo.svg" alt="Heimrath" />
								</Link>
							</div>
						</div>
						<div className="col-auto">
							<div className="header-grid-info">
								<ul>
									<li>
										<div className="icon">
											<i className="fas fa-clock" />
										</div>
										<div className="header-grid-info-details">
											<p>Öffnungszeiten:</p>
											<h6>Montag - Donnerstag</h6>
											<h6>07:30 - 17:30</h6>
											<h6>Freitag: 07:30 - 16:00</h6>
										</div>
									</li>
									<li>
										<div className="icon">
											<i className="fas fa-map-marker-alt" />
										</div>
										<div className="header-grid-info-details">
											<Link
												to="https://www.google.com/maps/place/Heimrath+Wohnmobil+und+KFZ-Service+GmbH/@48.15105,11.2551737,20z/data=!4m15!1m8!3m7!1s0x479dd5467d0c0b71:0x731f1ed863a931d!2sAmmerseestra%C3%9Fe+15,+82239+Alling!3b1!8m2!3d48.1512183!4d11.2551772!16s%2Fg%2F11c4jch3rt!3m5!1s0x479dd5467eaafce3:0xd51cfb85657b3cae!8m2!3d48.1513431!4d11.2551014!16s%2Fg%2F1tffsx_y?entry=ttu"
												target="_blank"
												rel="noopener noreferrer">
												<p>Ammerseestr. 15</p>
												<h6>82239 Alling-Biburg</h6>
											</Link>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`sticky-wrapper ${scroll && "sticky"}`}>
				{showMenu ? (
					<div className="menu-area">
						<div className="container">
							<div className="row align-items-center justify-content-between">
								<div className="col-auto header-navbar-logo">
									<div className="header-logo">
										<Link to="/">
											<img src="assets/img/logo-white.svg" alt="Heimrath" />
										</Link>
									</div>
								</div>
								<div className="col-auto">
									<nav className="main-menu d-none d-lg-inline-block">
										<ul>
											<li>
												<Link to="#">
													<ScrollLink to="aboutUs" smooth={true} duration={500}>
														Über Uns
													</ScrollLink>
												</Link>
											</li>
											<li>
												<Link to="#">
													<ScrollLink to="reviews" smooth={true} duration={500}>
														Kundenmeinungen
													</ScrollLink>
												</Link>
											</li>
											<li>
												<Link to="#">
													<ScrollLink to="portfolioSection" smooth={true} duration={500}>
														Unser Services
													</ScrollLink>
												</Link>
											</li>
											<li>
												<Link to="#">
													<ScrollLink to="ourService" smooth={true} duration={500}>
														Unsere Leistungen
													</ScrollLink>
												</Link>
											</li>
											<li>
												<Link to="#">
													<ScrollLink to="ourContact" smooth={true} duration={500}>
														Kontakt
													</ScrollLink>
												</Link>
											</li>
											<li>
												<NavLink to="/contact" className={(navData) => (navData.isActive ? "active" : "")}>
													Impressum
												</NavLink>
											</li>
										</ul>
									</nav>
									<div className="navbar-right d-inline-flex d-lg-none">
										<button type="button" className="menu-toggle icon-btn" onClick={mobileMenu}>
											<i className="fas fa-bars" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="menu-area">
						<div className="container">
							<div className="row align-items-center justify-content-between">
								<div className="col-auto header-navbar-logo">
									<div className="header-logo">
										<Link to="/">
											<img src="assets/img/logo-white.svg" alt="Heimrath" />
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="bumper bg-header"></div>
					</div>
				)}
				{/* Main Menu Area */}

				{/* Mobile Menu */}
				<div className={`mobile-menu-wrapper  ${active && "body-visible"}`}>
					<div className="mobile-menu-area">
						<div className="mobile-logo">
							<Link to="/">
								<img src="assets/img/logo.svg" alt="Heimrath" />
							</Link>
							<button className="menu-toggle" onClick={mobileMenu}>
								<i className="fa fa-times" />
							</button>
						</div>
						<div className="mobile-menu">
							<ul id="offcanvas-navigation">
								<li>
									<Link to="#">
										<ScrollLink to="aboutUs" smooth={true} duration={500}>
											Über Uns
										</ScrollLink>
									</Link>
								</li>
								<li>
									<Link to="#">
										<ScrollLink to="reviews" smooth={true} duration={500}>
											Kundenmeinungen
										</ScrollLink>
									</Link>
								</li>
								<li>
									<Link to="#">
										<ScrollLink to="portfolioSection" smooth={true} duration={500}>
											Unser Services
										</ScrollLink>
									</Link>
								</li>
								<li>
									<Link to="#">
										<ScrollLink to="ourService" smooth={true} duration={500}>
											Unsere Leistungen
										</ScrollLink>
									</Link>
								</li>

								<li>
									<Link to="#">
										<ScrollLink to="ourContact" smooth={true} duration={500}>
											Kontakt
										</ScrollLink>
									</Link>
								</li>
								<li>
									<NavLink to="/contact" className={(navData) => (navData.isActive ? "active" : "")}>
										Impressum
									</NavLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default HeaderTwo;
