import React from "react";
import { ElfsightWidget } from "react-elfsight-widget";

const GoogleReview = () => {
	return (
		<div className="container">
			<div className="title-area">
				<h2 className="sec-title white-text">Kundenmeinungen </h2>
			</div>
			<div className="row gy-40 counter-area-2 bg-theme">
				<ElfsightWidget widgetId="e1842f1a-8776-4166-91b5-9129330be4fa" lazy />
			</div>
		</div>
	);
};

export default GoogleReview;
