import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";

import ContactPage from "./pages/ContactPage";
import HomePageTwo from "./pages/HomePageTwo";
import DataSecPage from "./pages/DataSecPage";

function App() {
	return (
		<BrowserRouter>
			<RouteScrollToTop />
			<ScrollToTop smooth color="#E8092E" />
			<Routes>
				<Route exact path="/" element={<HomePageTwo />} />
				<Route exact path="/contact" element={<ContactPage />} />
				<Route exact path="/datasec" element={<DataSecPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
