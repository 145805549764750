import React, { useEffect, useState } from "react";

import FooterAreaTwo from "../components/FooterAreaTwo";
import ContactArea from "../components/ContactArea";
import Preloader from "../helper/Preloader";
import HeaderTwo from "../components/HeaderTwo";
import ImageImpressum from "../components/ImageImpressum";

const ContactPage = () => {
	let [active, setActive] = useState(true);
	useEffect(() => {
		setTimeout(function () {
			setActive(false);
		}, 2000);
	}, []);
	return (
		<>
			{/* Preloader */}
			{active === true && <Preloader />}

			{/* Header one */}
			<HeaderTwo showMenu={false} />

			{/* Breadcrumb */}
			{/* <Breadcrumb title={"Kontakt"} /> */}

			{/* Contact Area */}
			<ContactArea />

			<ImageImpressum/>
			
			{/* Footer Area One */}
			<FooterAreaTwo />
		</>
	);
};

export default ContactPage;
