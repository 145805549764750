import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs, EffectFade } from "swiper";
import { Link } from "react-router-dom";
const PortfolioTwo = () => {
	return (
		<div className="portfolio-area-1 space overflow-hidden">
			<div className="container">
				<div className="row justify-content-between align-items-end">
					<div className="col-xl-5 col-lg-6">
						<div className="title-area">
							<span className="sub-title">Unser Service</span>
							<h2 className="sec-title">
								Preiswerte Autoreparatur und die besten Lösungen{" "}
								<img className="title-bg-shape" src="assets/img/bg/title-bg-shape.png" alt="Heimrath" />
							</h2>
						</div>
					</div>
					<div className="col-sm-auto">
						<div className="title-area">
							<div className="icon-box">
								<button className="slick-arrow default portfolio-button-prev">
									<i className="fas fa-arrow-left" />
								</button>
								<button className="slick-arrow default portfolio-button-next">
									<i className="fas fa-arrow-right" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid p-0">
				<div className="row global-carousel gx-30 portfolio-slider1">
					<Swiper
						loop={true}
						navigation={{
							nextEl: ".portfolio-button-next",
							prevEl: ".portfolio-button-prev",
						}}
						spaceBetween={30}
						centeredSlides
						slidesPerView={1}
						slidesPerGroup={1}
						speed={2000}
						pagination={{ clickable: true }}
						autoplay={{ delay: 6000 }}
						className="mySwiper"
						modules={[FreeMode, Navigation, Thumbs, EffectFade]}
						breakpoints={{
							0: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
							768: {
								slidesPerView: 2,
							},
							992: {
								slidesPerView: 3,
							},
							1200: {
								slidesPerView: 3,
							},
							1400: {
								slidesPerView: 3,
							},
						}}>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/1.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Inspektion</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Regelgerechte Fahrzeugüberprüfungen für maximale Sicherheit und Werterhalt.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/2.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Hauptuntersuchung, Abgasuntersuchung, Gasprüfung</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Alle notwendigen Prüfungen aus einer Hand.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/3.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Klimaservice</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Komplettservice für Ihre Fahrzeugklimaanlage.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/4.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Reifenservice</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Reifenwechsel und professionelle Einlagerung Ihrer Saisonreifen.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/5.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Werkstattersatzwagen</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Mobilität auch während der Reparaturzeit.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/6.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Dometic Servicepartner</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Spezialservice für Dometic Produkte.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/7.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Webasto Standheizung Einbaupartner</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Einbau und Wartung von Webasto Standheizungen.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div>
								<div className="portfolio-card style2">
									<div className="portfolio-card-thumb">
										<img src="assets/img/portfolio/8.jpg" alt="Heimrath" />
									</div>
									<div className="portfolio-card-details">
										<div className="media-left">
											<h4 className="portfolio-card-details_title">
												<Link to="/project-details">Windschutzscheibenaustausch</Link>
											</h4>
											<span className="portfolio-card-details_subtitle">
												Schneller Austausch beschädigter Windschutzscheiben.
											</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default PortfolioTwo;
