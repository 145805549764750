import React from "react";

const DataSec = () => {
	return (
		<div className="wcu-area-1 space-bottom">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-5 col-lg-6">
						<div className="title-area text-center mt-20">
							<h2 className="sec-title">Datenschutzerklärung </h2>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				Allgemeiner Hinweis und Pflichtinformationen Benennung der verantwortlichen Stelle Die verantwortliche
				Stelle für die Datenverarbeitung auf dieser Website ist: Heimrath GmbH, Ammerseestr. 15, 82239
				Alling-Biburg. Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die
				Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
				Widerruf Ihrer Einwilligung zur Datenverarbeitung Nur mit Ihrer ausdrücklichen Einwilligung sind
				einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist
				jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der
				bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Recht auf Beschwerde bei
				der zuständigen Aufsichtsbehörde Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
				Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
				bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem
				sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
				Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
				https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html. Recht auf
				Datenübertragbarkeit Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
				Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
				Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten
				an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist. Recht
				auf Auskunft, Berichtigung, Sperrung, Löschung Sie haben jederzeit im Rahmen der geltenden
				gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
				personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und
				ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
				weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum
				aufgeführten Kontaktmöglichkeiten an uns wenden. SSL- bzw. TLS-Verschlüsselung Aus Sicherheitsgründen
				und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt
				unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website
				übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“
				Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile. Kontaktformular Per
				Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre
				Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
				findet ohne Ihre Einwilligung nicht statt. Je nach Art der Anfrage ist die Rechtsgrundlage für diese
				Verarbeitung Art. 6 Abs. 1 lit. b DSGVO für Anfragen, die Sie selbst im Rahmen einer vorvertraglichen
				Maßnahme stellen oder Art. 6 Abs. 1 S. 1 lit. f DSGVO, wenn Ihre Anfrage sonstiger Art ist. Sollten
				personenbezogene Daten abgefragt werden, die wir nicht für die Erfüllung eines Vertrages oder zur
				Wahrung berechtigter Interessen benötigen, erfolgt die Übermittlung an uns auf Basis einer von Ihnen
				abgegebenen Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO.t vom Widerruf unberührt. Über das
				Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
				Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
				Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt. Quelle:
				Datenschutz-Konfigurator von Herold Unternehmensberatung
			</div>
		</div>
	);
};

export default DataSec;
