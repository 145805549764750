import React from "react";
import { Link } from "react-router-dom";

const FooterAreaTwo = () => {
	return (
		<footer
			className="footer-wrapper footer-layout2"
			style={{ backgroundImage: "url(assets/img/bg/footer-bg2-1.png)" }}>
			<div className="container">
				{/* <div className="footer-top-2">
					<div className="footer-logo">
						<Link to="/">
							<img src="assets/img/logo-white.svg" alt="Heimrath" />
						</Link>
					</div>
					<h3 className="footer-top-title text-white">Subscribe our newsletter for updates</h3>
					<form className="newsletter-form">
						<div className="form-group">
							<input className="form-control" type="email" placeholder="Your Email Address" required="" />
						</div>
						<button type="submit" className="btn">
							<i className="fas fa-arrow-right" />
						</button>
					</form>
				</div> */}
				<div className="widget-area">
					<div className="row justify-center">
						{/* <div className="col-md-6 col-xl-3">
							<div className="widget footer-widget widget-about">
								<h3 className="widget_title">Über Uns</h3>
								<p className="footer-text mb-30">
								Mit mehr als 35 Jahren Erfahrung in der Automobilbranche und über 2.500 treuen Kunden, steht unsere Werkstatt für Qualität und Zuverlässigkeit.
								</p>
							</div>
						</div> */}

						<div className="col-md-6 col-xl-auto">
							<div className="widget footer-widget">
								<h3 className="widget_title">Kontakt</h3>
								<div className="widget-contact2">
									<div className="widget-contact-grid">
										<div className="icon">
											<i className="fas fa-map-marker-alt" />
										</div>
										<div className="contact-grid-details">
											<p>Adresse</p>
											<h6>
												Ammerseestr. 15, 82239 Alling-Biburg
												<p />
											</h6>
										</div>
									</div>
									<div className="widget-contact-grid">
										<div className="icon">
											<i className="fas fa-phone-alt" />
										</div>
										<div className="contact-grid-details">
											<p>Telefonnummer</p>
											<h6>
												<Link to="tel:0814144496">0 81 41-4 44 96</Link>
												<p />
											</h6>
										</div>
									</div>
									<div className="widget-contact-grid">
										<div className="icon">
											<i className="fas fa-envelope" />
										</div>
										<div className="contact-grid-details">
											<p>Email Adresse</p>
											<h6>
												<Link to="mailto:info@heimrath-gmbh.de">info@heimrath-gmbh.de</Link>
												<p />
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-wrap">
				<div className="container">
					<div className="row gy-3 justify-content-md-between justify-content-center">
						<div className="col-auto align-self-center">
							<p className="copyright-text text-center">
								© <Link to="#">Heimrath</Link> 2024 | All Rights Reserved |
								<Link to="/datasec">{' '}Datenschutzerklärung</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterAreaTwo;
