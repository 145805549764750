import React from "react";

const WhyChooseUsOne = () => {
	return (
		<div className="wcu-area-1 space-bottom">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-5 col-lg-6">
						<div className="title-area text-center">
							<span className="sub-title">Unsere Leistungen</span>
							<h2 className="sec-title">
								Qualität, die bewegt – Ihr Fahrzeug in besten Händen{" "}
								<img
									className="title-bg-shape shape-center"
									src="assets/img/bg/title-bg-shape.png"
									alt="Heimrath"
								/>
							</h2>
							<p className="sec-text">
								Zusätzlich zu unseren Leistungen wie Inspektionsarbeiten aller Art, Hauptuntersuchung nach § 29 StVZO,
								Räderwechsel, Reifenmontage, Rädereinlagerung, Vorabcheck zur Haupt- und Abgasuntersuchung,
								Ersatzteile Besorgung, bieten wir Ihnen folgende Leistungen an:
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row gy-40 gx-60 align-items-center">
					<div className="col-xl-4 order-xl-2">
						<div className="wcu-thumb-1">
							<img src="assets/img/normal/ww.jpg" alt="Heimrath" />
						</div>
					</div>
					<div className="col-xl-4 col-md-6 order-xl-1">
						<div className="wcu-card-wrap right-wrap">
							<div className="wcu-card">
								<div className="icon">
									<img src="assets/img/icon/wcu-icon_2-1.svg" alt="Heimrath" />
								</div>
								<div className="wcu-card-details">
									<h4 className="wcu-card-title">PKW</h4>
									<p className="wcu-card-text">
										<ul>
											<li>Instandsetzung und Instandhaltung</li>
											<li>Klimaanlagenservice</li>
											<li>Einbau von Standheizung</li>
											<li>Unfallinstandsetzung</li>
											<li>Anbauten, wie bspw. Anhängerkupplung</li>
										</ul>
									</p>
								</div>
							</div>

							<div className="wcu-card">
								<div className="icon">
									<img src="assets/img/icon/wcu-icon_2-3.svg" alt="Heimrath" />
								</div>
								<div className="wcu-card-details">
									<h4 className="wcu-card-title">Transporter</h4>
									<p className="wcu-card-text">
										<ul>
											<li>Instandsetzung und Instandhaltung</li>
											<li>Klimaanlagenservice</li>
											<li>Einbau von Standheizung</li>
											<li>Unfallinstandsetzung</li>
											<li>Anbauten, wie bspw. Anhängerkupplung</li>
										</ul>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-md-6 order-xl-3">
						<div className="wcu-card">
							<div className="icon">
								<img src="assets/img/icon/wcu-icon_2-4.svg" alt="Heimrath" />
							</div>
							<div className="wcu-card-details">
								<h4 className="wcu-card-title">Wohnmobil</h4>
								<p className="wcu-card-text">
									<ul>
										<li>Instandsetzung und Instandshaltung</li>
										<li>Klimaanlagenservice</li>
										<li>Unfallinstandsetzung</li>
										<li>Sonderaufbauten, wie bspw. Solaranlagen, Klimaanlagen, Wechselrichter, usw.</li>
										<li>Individuelle Anbauten, wie bspw. Motorradbühne, Anhängerkupplung, Fahrradträger, Zusatzluftfeder, usw</li>
										<li>Gasprüfung</li>
										<li>Camping-Ersatzteil-Bestellservice</li>
										<li>Dichtheitsprüfung Camper</li>
										<li>Auflastungen</li>
										<li>Umbauten</li>
										<li>Austausch von Dach- und Seitenfenster</li>
									</ul>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyChooseUsOne;
